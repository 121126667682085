import React from "react";
import studentstyles from "./students.module.scss";
import FeatureCard from "./Section1";
import FeatureCard2 from "./Section2";
import FeatureCard3 from "./Section3";
import Testimonials from "./Section4";
import Section5 from "./Section5";
import LearningSection from "./Section6";

const Students: React.FC = () => {
  return (
    <>
      <div className={studentstyles["students-container"]}>
        <h5 className={studentstyles["students-txt"]}>
          Benefits  &gt; for students
        </h5>
        <h1>Features and value additition</h1>
        <div className={studentstyles["tilted-rectangles"]}>
          <div></div>
          <div></div>
          <div></div>
        </div>

        <FeatureCard />

        <FeatureCard2 />

        <FeatureCard3 />

        <Testimonials />

        <Section5 />

        <LearningSection />
      </div>
      <div className={studentstyles["container3"]}>
      <div className={studentstyles["container"]}>
          <h2 className={studentstyles["heading"]}>Want to learn more?</h2>
          <div
            // onClick={() => alert("More info requested!")}
            className={studentstyles["button"]}
          >Request more info</div>
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
};

export default Students;
