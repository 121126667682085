import React from "react";
import Section1Styles from "./section.module.scss";
import boy from "assets/images/lock.png";

const LearningSection: React.FC = () => {
  return (
    <div className={Section1Styles["container"]}>
      <section className={Section1Styles["learning-section2"]}>
        <div className={Section1Styles["learning-box"]}>
          <div className={Section1Styles["left"]}>
            <h2>How we use your personal information</h2>
            <p>
            Updates were made to the UCAS Privacy Policy on 17 December 2024, within the ‘XOMBUS’ section to include further guidance on the retention period for call recordings. <br />
Our privacy policy tells you about the personal information we collect from you, how we use it, and what your rights are. We’ve divided it into headings you can select and view, depending on the services you are using. <br />
If we make any major changes to this policy, we’ll add an alert on this website and, if necessary, our social media channels or contact you. If you are under the age of 10, you can take a look around our website, but you shouldn’t give us your personal information, as our services are for people aged 10 or over.  <br />
If you've got any questions about our privacy policy, or how we use personal information, email at 
mailto:connect@xombus.com  <br />

            </p>
            
          </div>
        </div>
        <div className={Section1Styles["image"]}>
          <div className={Section1Styles["animated-dots-wrapper"]}>
            <div className={Section1Styles["animated-dots"]}>
              <img
                src={boy}
                alt="Animated Dots Example"
                className={Section1Styles["image"]}
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default LearningSection;
