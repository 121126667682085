import React from "react";
import Facultytyles from "./Faculty.module.scss";
import FeatureCard from "./Section1";
import FeatureCard2 from "./Section2";
import LearningSection from "./Section6";

const Privacy: React.FC = () => {
  return (
    <>
      <div className={Facultytyles["Faculty-container"]}>
        <h1>XOMBUS PRIVACY POLICY</h1>
        <div className={Facultytyles["tilted-rectangles"]}>
          <div></div>
          <div></div>
          <div></div>
        </div>

        <LearningSection />
        
        <FeatureCard />

        <FeatureCard2 />


      </div>
    </>
  );
};

export default Privacy;
