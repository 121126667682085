import React from "react";
import { CircleArrowRight} from "lucide-react"; 
import Section1Styles from "./section.module.scss";
import one from "assets/images/s61.png";
import two from "assets/images/s62.png";
import three from "assets/images/s63.png";

// Define props for a single card
interface CardProps {
  image: string;
  title: string;
  description: string;
  linkText: string;
}

// Card Component
const LearningCard: React.FC<CardProps> = ({ image, title, description, linkText }) => {
  return (
    <div className={Section1Styles["learning-card"]}>
      <img src={image} alt={title} className={Section1Styles["learning-card__image"]} />
      <div className={Section1Styles["learning-card__content"]}>
        <h3 className={Section1Styles["learning-card__content__title"]}>{title}</h3>
        <p className={Section1Styles["learning-card__content__description"]}>{description}</p>
        <a href="#" className={Section1Styles["learning-card__content__link"]}>
          {linkText} <CircleArrowRight color="#4FD1D9" size={16} />
        </a>
      </div>
    </div>
  );
};

// Section Component
const LearningSection: React.FC = () => {
  // Array of card data
  const cardsData = [
    {
      image: one,
      title: "Intellectual and personal growth",
      description: "AI-driven personalized learning helps students excel academically while fostering critical thinking and self-growth.",
      linkText: "Learn about The Xombus Experience",
    },
    {
      image: two,
      title: "Close-knit communities",
      description: "Xombus fosters meaningful connections, creating a vibrant and collaborative college community where students truly belong.",
      linkText: "Learn about The Xombus Experience",
    },
    {
      image: three,
      title: "Latest Global Education",
      description: "Stay ahead with Xombus, bringing cutting-edge global education resources and personalized learning to your college experience.",
      linkText: "Learn about The Xombus Experience",
    },
  ];

  return (
    <section className={Section1Styles["learning-section"]}>
      <h2 className={Section1Styles["learning-section__title"]}>
        Active, collaborative, reflective: learning, the XOMBUS way
      </h2>
      <div className={Section1Styles["learning-section__cards"]}>
        {cardsData.map((card, index) => (
          <LearningCard
            key={index}
            image={card.image}
            title={card.title}
            description={card.description}
            linkText={card.linkText}
          />
        ))}
      </div>
    </section>
  );
};

export default LearningSection;
