import React from "react";
import Section1Styles from "./section.module.scss";
import student1 from "assets/images/wer 1.png";
import student2 from "assets/images/student2.png";
import student3 from "assets/images/student3.png";
import student4 from "assets/images/student4.png";
import student5 from "assets/images/student5.png";
import student6 from "assets/images/student6.png";
import { Box } from "@mui/material";

const FeatureCard: React.FC = () => {
  return (
    <Box className={Section1Styles["cardContainer"]}>
      <div className={Section1Styles["image_text_container-1"]}>
        <div className={Section1Styles["container-1"]}>
          <div className={Section1Styles["text-box"]}>
            <h2>Tailored Learning Paths </h2>
            <h5>
              Xo identifies each student’s strengths and weaknesses to create
              customized study plans, addressing individual needs effectively.
            </h5>
          </div>
          <div className={Section1Styles["image-box"]}>
           <div className={Section1Styles["image-container"]}><img src={student1} alt="student" /></div> 
          </div>
        </div>
        <div className={Section1Styles["container-2"]}>
          <div className={Section1Styles["image-box"]}>
          <div className={Section1Styles["image-container"]}><img src={student2} alt="student" /></div>
          </div>
          <div className={Section1Styles["text-box"]}>
            <h2>Instant Doubt Resolution </h2>
            <h5>
            Students can ask course-specific or general academic questions and receive accurate, real-time answers from institutional or vetted external resources.
            </h5>
          </div>
        </div>
      </div>
      <div className={Section1Styles["image_text_container-2"]}>
        <div className={Section1Styles["container-1"]}>
        <div className={Section1Styles["image-box"]}>
           <div className={Section1Styles["image-container"]}><img src={student3} alt="student" /></div> 
          </div>
          <div className={Section1Styles["text-box"]}>
            <h2>Enhanced Resource Accessibility </h2>
            <h5>
            Xo connects students to relevant study materials, such as lecture notes, research papers, and eBooks, streamlining access to knowledge. 
            </h5>
          </div>
        </div>
        <div className={Section1Styles["container-2"]}>
      
          <div className={Section1Styles["text-box"]}>
            <h2>Personalized Feedback </h2>
            <h5>
            It provides detailed, student-specific feedback on assignments and quizzes, highlighting improvement areas and suggesting targeted resources. 
            </h5>
          </div>
          <div className={Section1Styles["image-box"]}>
          <div className={Section1Styles["image-container"]}><img src={student4} alt="student" /></div>
          </div>
        </div>
      </div>
      <div className={Section1Styles["image_text_container-3"]}>
        <div className={Section1Styles["container-1"]}>
          <div className={Section1Styles["text-box"]}>
            <h2>Exam and Study Assistance  </h2>
            <h5>
            Xo generates tailored quizzes, mock exams, and revision materials to help students prepare effectively for assessments. 
            </h5>
          </div>
          <div className={Section1Styles["image-box"]}>
           <div className={Section1Styles["image-container"]}><img src={student5} alt="student" /></div> 
          </div>
        </div>
        <div className={Section1Styles["container-2"]}>
          <div className={Section1Styles["image-box"]}>
          <div className={Section1Styles["image-container"]}><img src={student6} alt="student" /></div>
          </div>
          <div className={Section1Styles["text-box"]}>
            <h2>Research and Collaboration Support </h2>
            <h5>
            Facilitates access to relevant research papers and ideas, while enabling peer collaboration by connecting students with similar academic interests.
            </h5>
          </div>
        </div>
      </div>
    </Box>
  );
};

export default FeatureCard;
