import React, { Suspense } from 'react';
import { Route, Routes, BrowserRouter, Navigate } from 'react-router-dom';
import { appRoutes, ROUTES_ENUM } from 'constants/routes';
import ShellStyles from './Shell.module.scss';
import Layout from 'components/Layout';
import Home from 'containers/Home';
import Students from 'containers/Benefits/students';
import Faculty from 'containers/Benefits/Faculty';
import Privacy from 'containers/Privacy';
import Terms from 'containers/Terms';
import Cookie from 'containers/Cookie';
import Administration from 'containers/Benefits/Adminstration';

// const Login = React.lazy(() => import('containers/Login'));

const Shell: React.FC = () => {
  console.log('appRoutes:', appRoutes); // Debugging appRoutes
  console.log('ROUTES_ENUM:', ROUTES_ENUM); // Debugging ROUTES_ENUM

  return (
    <div className={ShellStyles['shell']}>
      <Suspense fallback={<div className="loader">Loading...</div>}>
        <BrowserRouter>
          <Routes>
            <Route
              path={appRoutes[ROUTES_ENUM.HOME]}
              element={
                <Layout>
                  <Home />
                </Layout>
              }
            />
            <Route
              path={appRoutes[ROUTES_ENUM.STUDENTS]}
              element={
                <Layout>
                  <Students />
                </Layout>
              }
            />
            <Route
              path={appRoutes[ROUTES_ENUM.FACULTY]}
              element={
                <Layout>
                  <Faculty />
                </Layout>
              }
            />
            <Route
              path={appRoutes[ROUTES_ENUM.ADMINSTRATION]}
              element={
                <Layout>
                  <Administration />
                </Layout>
              }
            />
            <Route
              path={appRoutes[ROUTES_ENUM.PRIVACY]}
              element={
                <Layout>
                  <Privacy />
                </Layout>
              }
            />
            <Route
              path={appRoutes[ROUTES_ENUM.TERMS]}
              element={
                <Layout>
                  <Terms />
                </Layout>
              }
            />
            <Route
              path={appRoutes[ROUTES_ENUM.COOKIE]}
              element={
                <Layout>
                  <Cookie />
                </Layout>
              }
            />
            {/* Redirect unmatched routes to home */}
            <Route path="*" element={<Navigate to={appRoutes[ROUTES_ENUM.HOME]} replace />} />
          </Routes>
        </BrowserRouter>
      </Suspense>
    </div>
  );
};

export default React.memo(Shell);
