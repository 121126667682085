import React from "react";
import Section1Styles from "./section.module.scss";
import { Box } from "@mui/material";
const FeatureCard2: React.FC = () => {
  return (
    <Box className={Section1Styles["cardContainer"]}>
      <ul className={Section1Styles["content"]}>
        <li>
          <strong>About XOMBUS</strong>
          <ul className={Section1Styles["sub-content"]}>
            <li className={Section1Styles["sub-conten"]}>
              We are the Universities and Colleges Admissions Service, a company
              incorporated in England and Wales with company number 02839815
              whose registered office is at Rosehill, New Barn Lane, Cheltenham,
              Gloucestershire, GL52 3LZ (‘UCAS’, ‘we’, ‘us’).
            </li>
            <li className={Section1Styles["sub-conten"]}>
              You can contact us by telephone on 0371 468 0 468, using
              our contact us page, or in writing to the above address.
            </li>
            <li className={Section1Styles["sub-conten"]}>
              Our role is to provide admissions services across a range of
              subject areas and study modes for education providers.
            </li>
          </ul>
        </li>
        <li>
          <strong>Changes to the Terms</strong>
          <ul className={Section1Styles["sub-content"]}>
            <li className={Section1Styles["sub-conten"]}>
              We reserve the right at all times to vary, change, alter, amend,
              add to or remove any of these Terms. Please ensure that you review
              these Terms each time you use the Website as you will be deemed to
              have accepted a variation if you continue to use the Website after
              the variation has been posted.
            </li>
            <li className={Section1Styles["sub-conten"]}>
               The current draft of these Terms was adopted and published on 20
              April 2016.
            </li>
          </ul>
        </li>
        <li className={Section1Styles["sub-conten"]}>
          <strong>Information about you and visits to the Website</strong>
          <ul className={Section1Styles["sub-content"]}>
            <li className={Section1Styles["sub-conten"]}>
               We process information about you in accordance with our privacy
              policy. By using the Website, you consent to such processing and
              you warrant that all data provided by you is accurate.
            </li>
            <li className={Section1Styles["sub-conten"]}>
              You will be asked to register, using certain personal details,
              before being given access to certain areas of our
              Website, including in order to make an application to an education
              provider using our Apply service. We may use the details you
              provide in future to contact you in accordance with our privacy
              policy. You agree to disclose true and complete information during
              the account registration process, and to update your information
              if and when it changes. Your use of our ‘Apply’ service is
              governed by the Declaration.
            </li>
          </ul>
        </li>
        <li>
          <strong>Access to and use of our Website</strong>
          <ul className={Section1Styles["sub-content"]}>
            <li className={Section1Styles["sub-conten"]}>
               You may only use the Website for lawful purposes. When using the
              Website, you must not:
            </li>

            <li className={Section1Styles["sub-conten"]}>
               decrypt, extract, disassemble, reverse-engineer or decompile the
              Website
            </li>
            <li className={Section1Styles["sub-conten"]}>
               use the Website in any way that is unlawful or fraudulent, or has
              any unlawful or fraudulent purpose or effect
            </li>
            <li className={Section1Styles["sub-conten"]}>
               include any portion of the Website in any other software program
            </li>
            <li className={Section1Styles["sub-conten"]}>
               develop methods to enable unauthorised parties to use the Website
            </li>
            <li className={Section1Styles["sub-conten"]}>
               create any modifications or derivative works directly or
              indirectly using the Website, or components thereof
            </li>
            <li className={Section1Styles["sub-conten"]}>
               knowingly transmit any data, send or upload any material that
              contains viruses, Trojan horses, worms, time-bombs, keystroke
              loggers, spyware, adware or any other harmful programs or similar
              computer code designed to adversely affect the operation of any
              computer software or hardware of the Website
            </li>
            <li className={Section1Styles["sub-conten"]}> damage, interfere with or disrupt the Website in any way</li>
            <li className={Section1Styles["sub-conten"]}>
               use the Website or information obtained from the Website to
              transmit, or procure the sending of, any unsolicited or
              unauthorised advertising or promotional material or any other form
              of similar solicitation (spam)
            </li>
            <li className={Section1Styles["sub-conten"]}>
               use the Website for any purpose that is unlawful, improper or
              illegal, or in breach of any, or for the purpose of breaching any,
              law
            </li>
            <li className={Section1Styles["sub-conten"]}>
               use the Website in a way which infringes the rights of or
              harasses anyone else, is harmful to, or otherwise restricts or
              inhibits anyone else's use and enjoyment of the Website
            </li>
            <li className={Section1Styles["sub-conten"]}>
               use the Website to impersonate any person or entity or falsely
              state or misrepresent your affiliation with a person or entity
            </li>
            <li className={Section1Styles["sub-conten"]}>
               post comments about yourself which are not in good faith and in
              accordance with your honestly held beliefs
            </li>
            <li className={Section1Styles["sub-conten"]}>
               use the Website for the purpose of harming or attempting to harm
              minors in any way
            </li>
            <li className={Section1Styles["sub-conten"]}>
               use contact details obtained using the Website to harass any
              person
            </li>
            <li className={Section1Styles["sub-conten"]}>
               Access to the Website is permitted on a temporary basis, and we
              reserve the right to withdraw or amend the service we provide on
              our Website without notice. We will not be liable if for any
              reason the Website is unavailable at any time or for any period. 
            </li>
            <li className={Section1Styles["sub-conten"]}>
               We will use our reasonable endeavours to make the Website
              available but cannot guarantee that the Website will operate
              continuously or without interruption or be error-free. You must
              not attempt to interfere with the proper working of the Website
              and, in particular, you must not attempt to circumvent security,
              tamper with, hack into, or otherwise disrupt any computer system,
              server, website, router or any other internet connected device.
              You are also advised to use the appropriate antivirus software
              before and during access to the Website.
            </li>
            <li className={Section1Styles["sub-conten"]}>
               We reserve the right, at any time and for any reason or none, to
              suspend or terminate your access to, or use of, the Website, or
              any part thereof. We may also restrict access to some parts of our
              Website to users who have registered with us.
            </li>
            <li className={Section1Styles["sub-conten"]}>
               Where you choose or are provided with a Personal ID, code,
              password or any other piece of information as part of our security
              procedures, you must treat such information as confidential, and
              you must not disclose it to any third party. Please ensure that
              you log off when not using the Website and ensure that devices
              which you use to access the Website are securely locked when not
              in your possession. You must notify us if you suspect there has
              been any unauthorised use of your Personal ID, code or password or
              any other breach of security.
            </li>
            <li className={Section1Styles["sub-conten"]}>
               We have the right to disable any Personal ID, code or password,
              whether chosen by you or allocated by us, at any time, if in our
              opinion you have failed to comply with any of the provisions of
              these Terms. We will not be liable for any loss or damage
              whatsoever resulting from the disclosure of your Personal ID, code
              or password contrary to these Terms. 
            </li>
          </ul>
        </li>
        <li>
          <strong>Intellectual property rights</strong>
          <ul className={Section1Styles["sub-content"]}>
            <li className={Section1Styles["sub-conten"]}>
               We are the owner or licensee of all copyright and other
              intellectual property rights in any material contained on the
              Website.
            </li>
            <li className={Section1Styles["sub-conten"]}>
               You may access any part of the Website and download or copy
              material (by printing off individual pages onto paper) or download
              material on to disk (but not on to any server or other device
              connected to a network) for personal non-commercial use only. The
              intellectual property rights in such material shall be retained by
              UCAS or, where the material has been licensed to UCAS, by the
              owners of the rights in the material. Copying, distributing or any
              use of the material contained on the Website for any commercial
              purpose is prohibited.
            </li>
            <li className={Section1Styles["sub-conten"]}>
               The Website contains trademarks which belong to UCAS or which
              have been licensed to UCAS by the trademark owners. Any use of
              these trademarks by you is forbidden unless prior written
              permission has been obtained from the trademark owner. UCAS will
              take legal action against any individual or organisation which
              seeks to use UCAS' name, material, logos or trademarks for
              unlawful purposes.
            </li>
            <li className={Section1Styles["sub-conten"]}>
              Trademarks on the Website belong to UCAS or are licensed to UCAS.
              Unauthorized use is forbidden.
            </li>
            <li className={Section1Styles["sub-conten"]}>
               You may not remove any copyright, trademark or intellectual
              property notices contained in the original material from any
              material downloaded or copied from the Website.
            </li>
            <li className={Section1Styles["sub-conten"]}>
               Links to the Website are not permitted, other than links to the
              homepage for your personal use, except with our prior written
              permission. Links to the website from within a frameset definition
              are not permitted except with our prior written permission.
            </li>
            <li className={Section1Styles["sub-conten"]}>
               You may not create a database by systematically downloading
              substantial parts of the Website.
            </li>
            <li className={Section1Styles["sub-conten"]}>
               The data we make available through our website, in the 'Data and
              analysis' section, that is provided in an open data format
              (typically CSV), is licensed subject to the terms of the Creative
              Commons Attribution 4.0 International Public License, as amended
              from time to time.
            </li>
            <li className={Section1Styles["sub-conten"]}>
               Any rights not expressly granted in these Terms, or any document
              referenced in them, are reserved to UCAS.
            </li>
          </ul>
        </li>
        <li>
          <strong>Uploading content to the Website</strong>
          <ul className={Section1Styles["sub-content"]}>
            <li className={Section1Styles["sub-conten"]}>
               When you are invited to upload video content you have created
              (‘Content’) you can do this by submitting it to us via our web
              form. UCAS is not liable for technical, hardware, or software
              failures of any kind or lost, inconsistent or unavailable network
              connections that may limit or prohibit Content being uploaded.
              We may use any Content you upload, but we cannot promise to. You
              warrant that you are legally entitled to post the Content and to
              grant to us the licence referred to in clause 6.3 below.
            </li>
            <li className={Section1Styles["sub-conten"]}>
               By uploading video content you confirm that it is your own work.
              You undertake that, prior to uploading any Content to our Website,
              you have permission, including any assignments, waivers, licences,
              rights, consents, and permissions (‘Permissions’) in respect of
              anyone else's rights in or to the Content (including, without
              limitation, all intellectual property rights, moral rights,
              performance rights (which include any music used in your video)
              rights to privacy, image rights and publicity rights), which give
              you (and us, relying on your agreement to these Terms) the right
              to immediately use, publish, reproduce, distribute, produce
              derivative works of, edit, perform, display and broadcast the
              uploaded Content in any media formats and through any media
              channels without liability to any third party. UCAS reserves the
              right to require proof that all necessary Permissions have been
              given and you agree, on request, to provide written evidence of
              those Permissions (including parental consent if necessary) and
              perform such acts as UCAS may reasonably require.
            </li>
            <li className={Section1Styles["sub-conten"]}> When you upload or post Content to our website, you: </li>
            <li className={Section1Styles["sub-conten"]}>
               grant to us an irrevocable, perpetual, worldwide, non-exclusive,
              royalty-free, transferable licence (with right to sub-license) to
              use, publish, reproduce, distribute, produce derivative works of,
              display, broadcast and perform the Content in part or as a whole,
              in any media formats and through any media channels
            </li>
            <li className={Section1Styles["sub-conten"]}> waive all moral rights in the Content</li>
            <li className={Section1Styles["sub-conten"]}>
               You agree that you will not upload any Content which is illegal,
              defamatory, obscene, incites prejudice or hatred or which it is
              unlawful for you to possess in the country in which you are
              resident, or which it would be unlawful for us to use or to hold.
            </li>
            <li className={Section1Styles["sub-conten"]}>
               If you are under 16 years of age, then by uploading Content you
              confirm that you have the permission of your parent or guardian to
              do so.
            </li>
            <li className={Section1Styles["sub-conten"]}>
               You warrant that any Content uploaded by you shall fully comply
              with these Terms, and you will be liable to us and indemnify us
              for any breach of that warranty. If you are in any doubt about
              your Content or whether or not (being under 16 years) you have
              your parent's or guardian's permission, then you should not upload
              it.
            </li>
            <li className={Section1Styles["sub-conten"]}>
              {" "}
              We shall be entitled to disclose your identity to any third party
              who claims that any Content uploaded by you infringes their
              rights.
            </li>
          </ul>
        </li>
        <li>
          <strong>General</strong>
          <ul className={Section1Styles["sub-content"]}>
            <li className={Section1Styles["sub-conten"]}>
              These Terms are not intended to confer any benefit on a third
              party under the provision of the Contracts (Rights of Third
              Parties) Act 1999.
            </li>
            <li className={Section1Styles["sub-conten"]}>
               These Terms shall be construed and interpreted in accordance with
              the laws of England and Wales. The courts of England shall have
              exclusive jurisdiction in relation to any claim, dispute or other
              matters arising therefrom, without prejudice to UCAS' right to
              bring proceedings in the courts of the country of residence of any
              user of the Website.
            </li>
            <li className={Section1Styles["sub-conten"]}>
               If any provision of these Terms is found to be invalid by any
              court having competent jurisdiction, the invalidity of that
              provision will not affect the validity of the remaining provisions
              of these Terms which shall continue to have full force and effect.
            </li>
            <li className={Section1Styles["sub-conten"]}>
               The failure by us to exercise any right or remedy under these
              terms and conditions shall not constitute a waiver of that right
              or remedy.
            </li>
          </ul>
        </li>
      </ul>
    </Box>
  );
};

export default FeatureCard2;
