import React from "react";
import Section1Styles from "./section.module.scss";
import { Box } from "@mui/material";
const FeatureCard2: React.FC = () => {
  return (
    <Box className={Section1Styles["cardContainer"]}>
      <ul className={Section1Styles["content"]}>
        <li>
          <strong>Why do we use cookies?</strong>
          <ul className={Section1Styles["sub-content"]}>
            <li className={Section1Styles["sub-conten"]}>
            Cookies are text files or pixel tags which are placed on your smartphone, tablet, laptop, or computer when you visit a website. We use cookies when you visit our site, but you can choose to not allow some types of cookies. Blocking some cookies may impact your experience of the site and the services we are able to offer.
            </li>
            <li className={Section1Styles["sub-conten"]}>
            The cookies we use do not access any personal data that you provide to UCAS as a charity, by this we mean the personal data you give to us so that we can process your application.
            </li>
            <li className={Section1Styles["sub-conten"]}>
            We may personalise ads that you see by tracking your online activity, such as pages visited and searches and when you favourite a page or a provider in the Hub, but only with your consent.
            </li>
          </ul>
        </li>
        <li>
          <strong>Necessary cookies:</strong>
          <ul className={Section1Styles["sub-content"]}>
            <li className={Section1Styles["sub-conten"]}>
            Necessary cookies help make our websites usable by enabling basic functions like page navigation and access to secure areas of the website.
            </li>
            <li className={Section1Styles["sub-conten"]}>
            The website cannot function properly without these cookies and you cannot opt out of these.
            </li>
          </ul>
        </li>
        <li>
          <strong>Preferences Cookies:</strong>
          <ul className={Section1Styles["sub-content"]}>
            <li className={Section1Styles["sub-conten"]}>
            Preference cookies allow a website to remember information that changes the way the website behaves or looks to make it better for you, like your preferred language or the region that you are in.
            </li>
          </ul>
        </li>
        <li>
          <strong>Statistics Cookies:</strong>
          <ul className={Section1Styles["sub-content"]}>
            <li className={Section1Styles["sub-conten"]}>
            Statistics cookies help us to understand how visitors interact with our sites by collecting and reporting information anonymously. They can analyse visitor numbers, usage, trends, and behaviour so we can monitor and improve the experience.
            </li>

          </ul>
        </li>
        <li>
          <strong> Marketing Cookies:</strong>
          <ul className={Section1Styles["sub-content"]}>
            <li className={Section1Styles["sub-conten"]}> 
            Marketing cookies can track visitor behaviour across websites.
            </li>
            <li className={Section1Styles["sub-conten"]}>
            If you opt in, they allow us to display targeted ads that are relevant and engaging for you.
            </li>
            <li className={Section1Styles["sub-conten"]}>
             The Website contains trademarks which belong to UCAS or which have been licensed to UCAS by the trademark owners. Any use of these trademarks by you is forbidden unless prior written permission has been obtained from the trademark owner. UCAS will take legal action against any individual or organisation which seeks to use UCAS' name, material, logos or trademarks for unlawful purposes.
            </li>
            <li className={Section1Styles["sub-conten"]}>
            We also want you to know that these cookies are used by our commercial subsidiary UCAS Media, which works with other third parties, advertisers, and social media platforms so that they can also share personalised content and ads with you in the same way that we do. To do this those platforms may combine this data with other data you have already provided to them or that they have collected when you used their service.
            </li>
            <li className={Section1Styles["sub-conten"]}>
            Cookies also allow us to assess the success of our advertising campaigns, offers and communications , by tracking user journeys across webpages.
            </li>
            <li className={Section1Styles["sub-conten"]}>We will not track visits to pages on UCAS.com with content of a sensitive nature.</li>
                 </ul>
        </li>
        <li>
          <strong>How to control or delete cookies</strong>
          <ul className={Section1Styles["sub-content"]}>
            <li className={Section1Styles["sub-conten"]}>
            You can do this through the ‘Help’ function in your browser settings. Please be aware that controlling or deleting cookies may impact the functionality of this website.
            </li>
            <li className={Section1Styles["sub-conten"]}>
            Alternatively, visit www.aboutcookies.org or <br />
            http://www.allaboutcookies.org for comprehensive information on how to control or delete cookies in a wide variety of browsers, including how to delete cookies from your computer. For information on how to do this on your mobile device – such as a smartphone or tablet – please refer to your handset manual. 
            </li>
          
          </ul>
        </li>
      </ul>
    </Box>
  );
};

export default FeatureCard2;
