import React from "react";
import Section1Styles from "./section.module.scss";
import f1 from "assets/images/f1.png";
import f2 from "assets/images/f2.png";
import f3 from "assets/images/f3.png";
import f4 from "assets/images/f4.png";

const cards = [
  {
    image: f1,
    title: "Applying to university",
    description: "When and how to apply for an undergraduate course.",
    borderRadius: "1em 5em ",
  },
  {
    image: f2,
    title: "Applying to a conservatoire",
    description: "All you need to know about performance-based courses.",
    borderRadius: "1em 5em ",
  },
  {
    image: f3,
    title: "Chat to students",
    description: "Learn about courses, accommodation, and nightlife.",
    borderRadius: "5em 1em",
  },
  {
    image: f4,
    title: "Apprenticeships",
    description: "Everything you need to know about UK apprenticeships.",
    borderRadius: "5em 1em",
  },
];

const FeatureCard: React.FC = () => {
  return (
    <div className={Section1Styles["card-grid"]}>
      {cards.map((card, index) => (
        <div
          key={index}
          className={Section1Styles["card"]}
          style={{ borderRadius: card.borderRadius }}
        >
          <img
            src={card.image}
            alt={card.title}
            className={Section1Styles["card-image"]}
          />
          <div className={Section1Styles["card-content"]}>
            <h3>{card.title}</h3>
            <p>{card.description}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default FeatureCard;
