import React from "react";
import Section1Styles from "./section.module.scss";
import phone from "assets/images/phone.png";

const Section5: React.FC = () => {
  return (
    <div className={Section1Styles["phone"]}>
      <div className={Section1Styles["left"]}>
        <h2>
          Join a Community, Be Part of the Class, Belong to the Collage, Live
          the Vibe
        </h2>
        <div className={Section1Styles["tilted-rectangles"]}>
          <div></div>
          <div></div>
          <div></div>
        </div>

        <p>
          College is where connections, growth, and learning come together. With
          Xombus, your college experience goes beyond the classroom, offering a
          dynamic and supportive community that empowers you to thrive. From
          personalized support to instant access to resources, Xombus transforms
          how you connect, collaborate, and excel, making your journey truly
          unforgettable.
        </p>
      </div>
      <div className={Section1Styles["right"]}>
        <img src={phone} className={Section1Styles["imphon"]} alt="phone" />
      </div>
    </div>
  );
};

export default Section5;
