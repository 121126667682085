import React from "react";
import Section1Styles from "./section.module.scss";
import student from "assets/images/aditi.png";
import bg from "assets/images/bg.png";
import bg2 from "assets/images/bg2.png";
import bg3 from "assets/images/bg3.png";
import bg4 from "assets/images/bg4.png";
import { Box } from "@mui/material";
import Spinner from "components/Spinner";
const FeatureCard2: React.FC = () => {
  const data = [
    {
      image: bg,
      title: "Resources",
      description:
        "Effortlessly access all your college materials, including lecture notes, eBooks, guides, and more—all in one place.",
    },
    {
      image: bg2,
      title: "Activities",
      description:
        "Dive into a vibrant college life with access to events, collaborative projects, and academic discussions that enrich your journey.",
    },
    {
      image: bg3,
      title: "Teaching tools",
      description:
        "Empower learning with advanced teaching tools through the RAG application, enabling interactive classes and seamless resource sharing.",
    },
    {
      image: bg4,
      title: "Learn from the best",
      description:
        "Experience exceptional teaching and personalized guidance, supported by cutting-edge technology and expert resources.",
    },
  ];
  return (
    <Box className={Section1Styles["cardContainer"]}>
      <div className={Section1Styles["image_text_container-3"]}>
        <div className={Section1Styles["container"]}>
          <div className={Section1Styles["container-1"]}>
            <div className={Section1Styles["text-box"]}>
              <h2>Aditi Mishra  </h2>
              <h5>A First-Year Engineering Student – NIT Warangal, India</h5>
              <p>
                "As a first-year student at an engineering college, the
                transition from school to college was tough. Xo became my
                lifeline. When I struggled with thermodynamics, I’d ask Xo for
                help, and it explained the concepts in simple terms. It also
                recommended lecture notes and practice problems from my
                professors’ repository. Before exams, Xo’s tailored quizzes
                helped me focus on topics where I was weak. It feels like having
                a personal tutor that’s always available to guide me." 
              </p>
            </div>
            <div className={Section1Styles["image-box"]}>
              <div className={Section1Styles["image-spinner-container"]}>
                {" "}
                <div className={Section1Styles["spinner"]}>
                  <Spinner />
                </div>
                <div className={Section1Styles["image-container"]}>
                  <img src={student} alt="student" />
                </div>{" "}
              </div>
            </div>
          </div>
          <div className={Section1Styles["container-2"]}>
            <div className={Section1Styles["resources-activities-container"]}>
              <div className={Section1Styles["flex-container"]}>
                {data.map((item) => (
                  <div
                    className={Section1Styles["card"]}
                    style={{
                      backgroundImage: `url(${item.image})`,
                    }}
                  >
                    <div className={Section1Styles["card-content"]}>
                      <h3 className={Section1Styles["card-title"]}>
                        {item.title}
                      </h3>
                      <p className={Section1Styles["card-description"]}>
                        {item.description}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
};

export default FeatureCard2;
