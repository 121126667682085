import React, { useState, useEffect } from "react";
import homestyles from "./home.module.scss";
import boxImage from "assets/images/Group 1171280705.png";
import image2 from "assets/images/Group 1171280706 .png";
import image3 from "assets/images/Group 1171280701.png";
import image4 from "assets/images/Group 1171280704.png";
import bars from "assets/images/bars.png";

interface BoxContentProps {
  image: string;
  title: string;
  description: string;
  fullDescription: string;
}

const BoxContent: React.FC<BoxContentProps> = ({
  image,
  title,
  description,
  fullDescription,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  // Check screen size to disable hover on mobile
  useEffect(() => {
    const checkScreenSize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);

    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);

  return (
    <div
      className={homestyles["box-content"]}
      onMouseEnter={() => !isMobile && setIsHovered(true)}
      onMouseLeave={() => !isMobile && setIsHovered(false)}
    >
      <div className={homestyles["box"]}>
        <img src={image} alt={title} className={homestyles["box-image"]} />
        <h3>{title}</h3>
        <p>{isHovered || isMobile ? fullDescription : description}</p>
        <img src={bars} alt="decorative" className={homestyles["img"]} />
      </div>
    </div>
  );
};

const Section2: React.FC = () => {
  return (
    <div className={homestyles["section-2-container"]}>
      <div className={homestyles["section-2-content"]}>
        <h2>Why Choose Us?</h2>
        <div className={homestyles["box-container"]}>
          <BoxContent
            image={boxImage}
            title="Personalized Learning"
            description="Our AI-driven platform tailors educational experiences to individual student needs, promoting engagement......"
            fullDescription="Our AI-driven platform tailors educational experiences to individual student needs, promoting engagement and success. By analyzing student data, we create adaptive learning pathways that address each learner’s strengths and areas for improvement."
          />
          <BoxContent
            image={image2}
            title="Enhanced Student Engagement"
            description="Through AI-assisted personalized learning tools, students experience increased engagement and improved academic performance......"
            fullDescription="Through AI-assisted personalized learning tools, students experience increased engagement and improved academic performance. Studies have shown a 45% increase in student engagement and a 30% improvement in academic performance among students using AI-assisted personalized learning tools."
          />
        </div>
        <div className={homestyles["box-container"]}>
          <BoxContent
            image={image3}
            title="Streamlined Administration"
            description="Automate routine administrative tasks, allowing staff to focus on more meaningful interactions......"
            fullDescription="Automate routine administrative tasks, allowing staff to focus on more meaningful interactions and decision making."
          />
          <BoxContent
            image={image4}
            title="Data-Driven Insights"
            description="Leverage analytics to gain insights into student 
behavior and institutional processes......"
            fullDescription="Leverage analytics to gain insights into student behavior and institutional processes, facilitating informed decision-making."
          />
        </div>
      </div>
    </div>
  );
};

export default Section2;
