import React, { useState } from 'react';
import { Menu, X } from 'lucide-react';
import navstyles from './navbar.module.scss';
import logo from 'assets/images/xombus-logo.png';

const Navbar: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className={navstyles['navbar']}>
      <div className={navstyles["navbar-container"]}>
        {/* Logo */}
       
        <div className={navstyles["navbar-logo"]}>
          <a href="./home">
          <img src={logo} alt="Xombus Logo" />
        </a>
       
        </div>

        {/* Hamburger Icon */}
        <div className={navstyles["navbar-hamburger"]} onClick={toggleMenu}>
          {isOpen ? <></> : <Menu size={24} />}
        </div>

        {/* Navbar Menu */}
        <div
          className={`${navstyles["navbar-menu"]} ${
            isOpen ? navstyles.active : ""
          }`}
        >
          {/* Hamburger Icon */}
        <div className={navstyles["navbar-hamburger"]} onClick={toggleMenu}>
          {isOpen ? <X size={24} /> : <Menu size={24} />}
        </div>
          <ul className={navstyles["navbar-links"]}>
            <li><a href="/">Services</a></li>
            <li><a href="/">Contact</a></li>
            <li><a href="/">About Us</a></li>
          </ul>
          <div className={navstyles["navbar-buttons"]}>
            <a href="https://gateway.xombus.com/"><button className={navstyles["signup-btn"]}>Sign Up</button></a>
            <a href="https://gateway.xombus.com/"> <button className={navstyles["login-btn"]}>Login</button></a>
           
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
