import React from "react";
import adminstyles from "./Admin.module.scss";
import image_1 from "assets/images/Frame 1171280869.png";
import image_2 from "assets/images/team_meeting.png";
import image_3 from 'assets/images/excel.png';
import image_4 from 'assets/images/colorful_hands.png';

const section2: React.FC = () => {
  return (
    <div className={adminstyles["section-2-container"]}>
      <div className={adminstyles["text-container"]}>
        <h1>Empowering Excellence in Learning</h1>
        <p>
          Education administration plays a pivotal role in shaping the
          foundation of academic excellence and fostering a supportive
          environment for learners and educators alike. This multifaceted field
          encompasses the planning, organizing, and management of educational
          institutions, ensuring they function efficiently and effectively.
        </p>
      </div>

      <div className={adminstyles["container-2"]}>
        <img src={image_1} alt="" />
        <div className={adminstyles["text-container"]}>
          <h1>Andrue John</h1>
          <p>
            john, the Dean of a mid-sized college, faced challenges with
            resource allocation and student engagement. Implementing Xombus’s
            platform allowed the administration to automate routine tasks and
            gain valuable insights into student needs. This led to more informed
            decision-making, optimized resource distribution, and a noticeable
            increase in overall student satisfaction.
          </p>
        </div>
      </div>

      <div className={adminstyles["container-3"]}>
        <h1>Emerging Trends in Education Administration</h1>
        <div className={adminstyles["image-container"]}>
          <div className={adminstyles["box"]}>
            <img src={image_2} alt="" />
            <h1>Digital Transformation</h1>
          </div>
          <div className={adminstyles["box"]}>
            <img src={image_3} alt="" />
            <h1>Data-Driven Decision Making</h1>
          </div>
          <div className={adminstyles["box"]}>
            <img src={image_4} alt="" />
            <h1>Equity And Inclusion</h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default section2;
