import React from "react";
import Section1Styles from "./section.module.scss";
import { Box } from "@mui/material";
const FeatureCard2: React.FC = () => {
  return (
    <Box className={Section1Styles["cardContainer"]}>
      <ul>
        <li>
          Browsing our website, contacting us, requesting a newsletter, entering
          a competition, completing a form, and responding to a survey
        </li>
        <li>
          Applying through our admissions schemes (UCAS Undergraduate or UCAS
          Conservatoires)
        </li>
        <li>Clearing Plus</li>
        <li>Using the ‘chat to students’ service</li>
        <li>Apprenticeship Smart Alerts</li>
        <li>
          Direct marketing, accessing student products, and how to opt out
        </li>
        <li>Social media and cookies</li>
        <li>If you provide support to an applicant</li>
        <li>If you are a legal member of XOMBUS</li>
        <li>Keeping your information secure and transfers of personal data</li>
        <li>Retention of personal information</li>
        <li>Personal information – your rights</li>
        <li>
          Use of images, including photographs and video footage with consent
        </li>
        <li>Other websites you can access from this one</li>
      </ul>
    </Box>
  );
};

export default FeatureCard2;
