import React from "react";
import Section1Styles from "./section.module.scss";
import student1 from "assets/images/hanna.png";
import student2 from "assets/images/ethan.png";
import { Box } from "@mui/material";
import Spinner from "components/Spinner";
const FeatureCard3: React.FC = () => {
  return (
    <Box className={Section1Styles["cardContainer"]}>
      <div className={Section1Styles["container"]}>
        <div className={Section1Styles["image_text_container-3"]}>
          <div className={Section1Styles["container-1"]}>
          <div className={Section1Styles["image-box"]}>
              <div className={Section1Styles["image-spinner-container"]}>
                {" "}
                <div className={Section1Styles["spinner"]}>
                  <Spinner />
                </div>
                <div className={Section1Styles["image-container"]}>
                  <img src={student1} alt="student" />
                </div>{" "}
              </div>
            </div>
            <div className={Section1Styles["text-box"]}>
              <h2>Hanna Putri</h2>
              <h5>MBA Student, Universitas Indonesia, Jakarta, Indonesia </h5>
              <p>
                "Balancing coursework, case studies, and internships was
                overwhelming. Xo helped me stay organized and focused. For group
                projects, Xo suggested strategies and shared relevant business
                case studies that aligned with our topics. When it came to
                preparing for exams, it analyzed my past performance and
                generated personalized revision materials. I even used Xo to
                clarify financial models I couldn’t fully grasp in class. It’s
                like having a reliable assistant that keeps me one step ahead." 
              </p>
            </div>
           
          </div>
        </div>
        <div className={Section1Styles["image_text_container-4"]}>
          <div className={Section1Styles["container-2"]}>
            <div className={Section1Styles["text-box"]}>
              <h2>Ethan Miller </h2>
              <h5>A First-Year Engineering Student – NIT Warangal, India</h5>
              <p>
                Working on my thesis about renewable energy technologies. Xo was
                a game-changer for my research. My professor and I collaborated
                using the platform—it would help me quickly pull up relevant
                research papers while my professor guided me in framing my
                arguments. Xo also simplified complex statistical concepts,
                making data analysis less daunting. The combination of my
                professor’s expertise and Xo’s instant support empowered me to
                present a thesis I’m truly proud of." 
              </p>
            </div>
            <div className={Section1Styles["image-box"]}>
              <div className={Section1Styles["image-spinner-container"]}>
                {" "}
                <div className={Section1Styles["spinner"]}>
                  <Spinner />
                </div>
                <div className={Section1Styles["image-container"]}>
                  <img src={student2} alt="student" />
                </div>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
};

export default FeatureCard3;
