import React from 'react'
import adminstyles from './Admin.module.scss';
import bars from 'assets/images/bars.png';
import image_1 from "assets/images/machine.png";
import image_2 from "assets/images/world.png";
import image_3 from "assets/images/Frame 1171280844.png";
import image_4 from "assets/images/Frame 1171280871.png";

const section3: React.FC = () => {
  return (
    <div className={adminstyles['section-3-container']}>
      <div className={adminstyles['image-container']}>
        <div className={adminstyles["box-1"]}>
            
            <div className={adminstyles["text-container"]}>
                <h1>Technological Integration</h1>
                <img src={bars} alt="" />
                <p>Adapting to the rapid growth of educational technologies like Learning Management Systems (LMS), virtual classrooms, and AI-powered tools.</p>
                <p>Training staff and faculty to effectively use digital platforms.</p>
            </div>
            <img src={image_1} alt="" />
        </div>
        <div className={adminstyles["box-2"]}>
        <img src={image_2} alt="" />
            <div className={adminstyles["text-container"]}>

                <h1>Globalization</h1>
                <img src={bars} alt="" />
                <p>Addressing the needs of an increasingly diverse and international student population</p>
                <p>Incorporating global perspectives into curriculum and operations.</p>
            </div>
            
        </div>
      </div>

      <div className={adminstyles['image-container-2']}>
        <div className={adminstyles["box-1"]}>
            
            <div className={adminstyles["text-container"]}>
                <h1>Federal Programs</h1>
                <p>The  Programs are federal outreach and student services programs designed to identify and provide services for individuals from disadvantaged backgrounds.</p>
            </div>
            <img src={image_3} alt="" />
        </div>
        <div className={adminstyles["box-2"]}>
        <img src={image_4} alt="" />
            <div className={adminstyles["text-container"]}>

                <h1>Get subscription to start your preparation</h1>
                <button><span>View subscription plans</span></button>
            </div>
            
        </div>
      </div>
    </div>
  )
}

export default section3
