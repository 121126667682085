import React from 'react';
import Section1Styles from "./section.module.scss";
import fc1 from "assets/images/face_2.png";
import fc2 from "assets/images/face_3.png";
import fc3 from "assets/images/face_4.png";
interface Testimonial {
  id: number;
  text: string;
  name: string;
  title: string;
  image?: string;
}

const testimonials: Testimonial[] = [
  {
    id: 1,
    text: "Xo's tailored learning paths helped me ace my toughest subject by focusing on my weak areas. It's like having a personal tutor, always available to guide me!",
    name: 'Kunal Sharma,',
    title: 'Computer Science Student',
    image: fc1, // Replace with actual image URL
  },
  {
    id: 2,
    text: 'Instant doubt resolution has been a lifesaver during late-night study sessions. Xo answers my questions in real-time, making learning seamless.',
    name: 'Liam Marromi,',
    title: 'Engineering Student',
    image: fc2, // Replace with actual image URL
  },
  {
    id: 3,
    text: 'Accessing study materials has never been easier. With Xo, I get lecture notes, research papers, and eBooks all in one place—no time wasted!',
    name: 'Ava Martin,',
    title: 'Business Administration Student',
    image: fc3, // Replace with actual image URL
  },
];

const Testimonials: React.FC = () => {
  return (
    <div className={Section1Styles["testimonials"]}>
      <h2>From our Students</h2>
      <div className={Section1Styles["testimonials__list"]}>
        {testimonials.map(({ id, text, name, title, image }) => (
          
          <div className={Section1Styles['boxp']}>  
          <div key={id} className={Section1Styles["testimonial"]}>
            <div className={Section1Styles["box"]}>
            <div className={Section1Styles["txt"]}>{text}</div>
            <div className={Section1Styles["img-txt"]}>
              <div className={Section1Styles["img"]}>
                 <img src={image} alt={name} className={Section1Styles["inner-image"]}/>
              </div>
              <div className={Section1Styles["itxt"]}>
                <h3>{name}</h3>
                <span>{title}</span>
              </div>
            </div>
          </div></div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Testimonials;
