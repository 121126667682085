import React from "react";
import Section1Styles from "./section.module.scss";
import boy from "assets/images/touchphone.png";

const LearningSection: React.FC = () => {
  return (
    <div className={Section1Styles["container"]}>
      <section className={Section1Styles["learning-section2"]}>
        <div className={Section1Styles["learning-box"]}>
          <div className={Section1Styles["left"]}>
            <h2>How we use your personal information
            </h2>
            <p>
            These are the terms and conditions (the ‘Terms’) on which you may make use of the website www.xombus.com (a ‘Website’), whether as a guest or a registered user. Please read these Terms carefully before you start to use the Website. By using the Website, regardless of how you access it, you confirm that you accept these Terms and that you agree to abide by them.
            </p>
            <h3>If you do not agree to these Terms, please refrain from using the Website</h3>
          </div>
        </div>
        <div className={Section1Styles["image"]}>
          <div className={Section1Styles["animated-dots-wrapper"]}>
            <div className={Section1Styles["animated-dots"]}>
              <img
                src={boy}
                alt="Animated Dots Example"
                className={Section1Styles["image"]}
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default LearningSection;
