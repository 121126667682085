import React from "react";
import Section1Styles from "./section.module.scss";
import girl from "assets/images/girlleptop.png";
import boy from "assets/images/boylaptop.png";

const LearningSection: React.FC = () => {
  return (
    <div className={Section1Styles["container"]}>
      <section className={Section1Styles["learning-section"]}>
        <div className={Section1Styles["image"]}>
          <img src={girl} alt="" />
        </div>
        <div className={Section1Styles["learning-box"]}>
          <div className={Section1Styles["left"]}>
            <h4>THE KEY TO GETTING ACCEPTED</h4>
            <h2>Digital SAT Test Prep Course</h2>
            <p>
              Peterson’s Digital SAT prep course and SAT practice testshave been
              carefully crafted by education experts so studentscan go into test
              day with confidence and earn a higher SAT score.{" "}
            </p>
            <div className={Section1Styles["imgstor"]}>
              <button className={Section1Styles["signup-btn"]}>
                Start Now
              </button>
            </div>
          </div>
        </div>
      </section>
      <section className={Section1Styles["learning-section2"]}>
        <div className={Section1Styles["learning-box"]}>
          <div className={Section1Styles["left"]}>
            <h4>WHY STOP AT ONE?</h4>
            <h2>Gain Unlimited Access</h2>
            <p>
              Your subscription gives you unlimited access to over 250 courses,
              including over 60 CLEP and DSST courses, so you can prepare for
              exams, excel in your classes, earn college credit, and save
              thousands of dollars.
            </p>
            <div className={Section1Styles["imgstor"]}>
              <button className={Section1Styles["signup-btn"]}>
                Start Now
              </button>
            </div>
          </div>
        </div>
        <div className={Section1Styles["image"]}>
          <div className={Section1Styles["animated-dots-wrapper"]}>
            <div className={Section1Styles["animated-dots"]}>
              <img
                src={boy}
                alt="Animated Dots Example"
                className={Section1Styles["image"]}
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default LearningSection;
