export enum ROUTES_ENUM {
    // LOGIN = 'LOGIN',
    // SIGNUP = 'SIGNUP',
    // SERVICES='SERVICES',
    // CONTACT='CONTACT',
    // ABOUT_US='ABOUT-US',
    // DASHBOARD='DASHBOARD',
    // DISCLAIMER='DISCLAIMER',
    COOKIE='COOKIE',
    TERMS='TERMS',
    PRIVACY = 'PRIVACY',
    ADMINSTRATION='ADMINSTRATION',
    HOME='HOME',
    STUDENTS='STUDENTS',
    FACULTY='FACULTY',
  }
  
  export const appRoutes = {
    // [ROUTES_ENUM.LOGIN]: '/login',
    // [ROUTES_ENUM.SIGNUP]: '/signup',
    // [ROUTES_ENUM.SERVICES]: '/services',
    // [ROUTES_ENUM.CONTACT]: '/contact',
    // [ROUTES_ENUM.ABOUT_US]: '/about',
    // [ROUTES_ENUM.DASHBOARD]: '/dashboard',
    // [ROUTES_ENUM.DISCLAIMER]: '/disclaimer',
    [ROUTES_ENUM.ADMINSTRATION]: '/adminstration',
    [ROUTES_ENUM.COOKIE]: '/cookie',
    [ROUTES_ENUM.TERMS]: '/terms',
    [ROUTES_ENUM.PRIVACY]: '/privacy',
    [ROUTES_ENUM.FACULTY]: '/faculty',
    [ROUTES_ENUM.HOME]: '/home',
    [ROUTES_ENUM.STUDENTS]: '/students',

  };