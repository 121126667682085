import React from "react";
import Facultytyles from "./Faculty.module.scss";
import FeatureCard2 from "./Section2";
import LearningSection from "./Section6";

const Terms: React.FC = () => {
  return (
    <>
      <div className={Facultytyles["Faculty-container"]}>
        <h1>Terms of Service</h1>
        <div className={Facultytyles["tilted-rectangles"]}>
          <div></div>
          <div></div>
          <div></div>
        </div>

        <LearningSection />
        
        <FeatureCard2 />


      </div>
    </>
  );
};

export default Terms;
