import React from "react";
import Section1Styles from "./section.module.scss";
import hndshk from "assets/images/handshake.png";

const FeatureCard: React.FC = () => {
  return (
    <div className={Section1Styles["card-grid"]}>
      <div className={Section1Styles["teams"]}>
       <li></li>
        <h2>Terms used in this policy</h2>
      </div>
      <div className={Section1Styles["card-image"]}>
        {" "}
        <img src={hndshk} alt="" />{" "}
      </div>

      <div className={Section1Styles["who"]}>
        <h2>Who we are</h2>
        <p>
          XOMBUS – we manage applications to higher and further education in the
          UK. We offer some of our services through our wholly owned trading
          subsidiary, UCAS Media Limited. From time to time your data may pass
          between UCAS and UCAS Media as the UCAS Group. The registered office
          for the UCAS Group is Rosehill, New Barn Lane, Cheltenham,
          Gloucestershire, GL52 3LZ. This policy refers to the UCAS Group as
          ‘UCAS’, UCAS’, ‘our’, ‘us’, or ‘we’. <br />
          All personal information provided by you through this website is under
          the control of UCAS, and we’re responsible for taking care of your
          personal information in line with the Data Protection Act 2018, and
          the UK General Data Protection Regulation (GDPR).
        </p>
      </div>
      <div className={Section1Styles["who"]}>
        <h2>Terms used in this policy</h2>
        <p>
          When explaining how we use your personal information, we use quite a
          few terms to describe our services. If you don’t understand any of
          them, you can check what they mean on the 'UCAS terms explained' page.{" "}
          <br />
          We try to use language that is easy to understand and avoid legal
          terms. However, we’re required by law to include certain information
          in our privacy policy – here we explain what these terms mean: <br />{" "}
          <br />
          UK General Data Protection Regulation (GDPR) is a regulation on data
          protection and privacy, that applies within the United Kingdom (UK).
          The regulation provides individual control and rights in respect of
          personal information.
          <br /> <br />
          <ul>
            <li>
              Data Protection Act 2018 (DPA), along with the GDPR, sets the
              regime for handling personal data within the UK.   
            </li>
            <li>
              Personal data is information about individuals, like name and
              address, or educational or work achievements. Our policy may refer
              to personal data as ‘personal information’.
            </li>
            <li>
              ‘Special category’ personal data is personal information the GDPR
              considers to be sensitive, such as information about ethnicity,
              sexual orientation, religious beliefs, or mental and physical
              health. Use of this personal information is subject to stricter
              conditions under the GDPR.
            </li>
            <li>
              Criminal convictions personal data is personal information about
              someone’s criminal convictions. If you apply to some courses, such
              as ones that involve working with children, we will ask you if you
              have any criminal convictions.
            </li>
            <li>
              Lawful basis for processing – all uses of personal information
              must be supported by a ‘lawful basis for processing’. This means
              there must be provisions in the GDPR or DPA allowing the use of
              personal information for specific purposes.
            </li>
          </ul>
        </p>
      </div>
    </div>
  );
};

export default FeatureCard;
