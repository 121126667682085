import React from "react";
import Facultytyles from "./Faculty.module.scss";
import FeatureCard from "./Section1";
import FeatureCard2 from "./Section2";
import FeatureCard3 from "./Section3";
import Testimonials from "./Section4";
import Section5 from "./Section5";
import LearningSection from "./Section6";

const Faculty: React.FC = () => {
  return (
    <>
      <div className={Facultytyles["Faculty-container"]}>
        <h5 className={Facultytyles["Faculty-txt"]}>
          Benefits &gt; for Faculty
        </h5>
        <h1>Heading</h1>
        <div className={Facultytyles["tilted-rectangles"]}>
          <div></div>
          <div></div>
          <div></div>
        </div>

        <FeatureCard />

        <FeatureCard2 />

        <FeatureCard3 />

        <Testimonials />

        <Section5 />

        <LearningSection />
      </div>
    </>
  );
};

export default Faculty;
