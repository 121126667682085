import React from "react";
import Section1Styles from "./section.module.scss";
import fimg from "assets/images/fimg.png";
import { Box } from "@mui/material";
import Spinner from "components/Spinner";
const FeatureCard3: React.FC = () => {
  return (
    <Box className={Section1Styles["cardContainer"]}>
      <div className={Section1Styles["container-1"]}>
        <div className={Section1Styles["image-box"]}>
          <div className={Section1Styles["image-spinner-container"]}>
            {" "}
            <div className={Section1Styles["spinner"]}>
              <Spinner />
            </div>
            <div className={Section1Styles["image-container"]}>
              <img src={fimg} alt="student" />
            </div>{" "}
          </div>
        </div>
        <div className={Section1Styles["text-box"]}>
          <h2>Jaxon Ellis</h2>
          <p>
            Professor Jaxon, teaching introductory physics, found it challenging
            to address the diverse learning paces of her students. With Xombus’s
            AI tools, she could monitor individual progress and provide
            personalized feedback efficiently. This enabled her to dedicate more
            time to interactive teaching, resulting in improved student
            comprehension and engagement.
          </p>
        </div>
      </div>
    </Box>
  );
};

export default FeatureCard3;
