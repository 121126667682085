import React from "react";
import Section1Styles from "./section.module.scss";
import p1 from "assets/images/p1.png";
import p2 from "assets/images/p2.png";
import p3 from "assets/images/p3.png";
import { Box } from "@mui/material";
const FeatureCard2: React.FC = () => {
  const data = [
    {
      image: p1,
      title: "Practice Tests",
      description:
        "Mimic test day, uncover your strengths and weaknesses, boost your time management skills, and make every study session a step closer to expanding your career opportunities.",
    },
    {
      image: p2,
      title: "Flashcards",
      description:
        "Actively recall key concepts and terms with online flashcard sets—designed for you so you can seamlessly and confidently replay and memorize the most important content for exam day.",
    },
    {
      image: p3,
      title: "Interactive Lessons + Videos",
      description:
        "Our instructional videos and interactive lessons have been crafted by education experts for all learning styles in order to boost engagement and knowledge retention.",
    },
  ];
  return (
    <Box className={Section1Styles["cardContainer"]}>
      <div className={Section1Styles["container-1"]}>
        <h2>Tools for Success</h2>
        <div className={Section1Styles["txt"]}>
          Transform test anxiety into confidence with proven tools that will
          help you succeed on test day and get accepted into the college you
          want to attend.
        </div>
      </div>
      <div className={Section1Styles["container-2"]}>
        <div className={Section1Styles["flex-container"]}>
          {data.map((item) => (
            <div className={Section1Styles["card"]}>
              <img src={item.image} alt="" />
              <h3 className={Section1Styles["card-title"]}>{item.title}</h3>
              <p className={Section1Styles["card-description"]}>
                {item.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </Box>
  );
};

export default FeatureCard2;
