import React from "react";
import homestyles from "./home.module.scss";
import image_1 from "assets/images/image.png";
import image_2 from "assets/images/moro.png";
import bars from "assets/images/bars.png";
import Section2 from "./section2";
import Section3 from "./section3";
import Section4 from "./section4";
import FAQ from "containers/FAQ";

const Home: React.FC = () => {
  return (
    <div className={homestyles["home-container"]}>
      <div className={homestyles["container-1"]}>
        <div className={homestyles["text-container"]}>
          <h3>Welcome to Xombus</h3>
          <p>
            Empowering Higher Education with AI-Driven Personalized Learning
          </p>
        </div>
        <img src={image_1} alt="logo" />
      </div>
      <div className={homestyles["container-2"]}>
        <img src={image_2} alt="logo" />
        <div className={homestyles["text-container"]}>
          <h3>Transforming Education with AI Innovation</h3>
          <img src={bars} alt="logo" />
          <p>
            Xombus offers a next-generation platform for higher education
            institutions to unlock limitless possibilities in managing and
            imparting education. Designed with cutting-edge AI, our platform
            empowers institutions to streamline operations, enhance teaching
            methodologies, and redefine the learning experience.
          </p>
        </div>

      </div>

      <div className={homestyles["container-3"]}>
          <Section2 />
        </div>
        <div className={homestyles["container-4"]}>
          <Section3 />
        </div>
        <div className={homestyles["container-5"]}>
          <Section4 />
        </div>
        <div className={homestyles["container-6"]}>
          <FAQ />
        </div>

    </div>
  );
};

export default Home;
