// Adminstration.tsx
import React from "react";
import adminstyles from "./Admin.module.scss";
import image from "assets/images/indepent_woman.png";
import image_2 from "assets/images/image (1).png";
import image_3 from "assets/images/image (2).png";
import image_4 from "assets/images/image (3).png";
import Section2 from "./section2";
import Section3 from "./section3";

const Adminstration: React.FC = () => {
  const cards = [
    {
      image: image,
      title: "Policy Implementation",
      subtitle:
        "Establishing and enforcing educational policies to meet institutional goals and adhere to legal standards.",
    },
    {
      image: image_2,
      title: "Resource Management",
      subtitle:
        "Allocating budgets, staffing, and physical resources to optimize institutional performance.",
    },
    {
      image: image_3,
      title: "Student Support",
      subtitle:
        "Creating programs and initiatives to enhance student engagement, welfare, and academic success.",
    },
    {
      image: image_4,
      title: "Faculty Development",
      subtitle:
        "Promoting professional growth opportunities for teachers and staff to maintain high teaching standards.",
    },
  ];

  return (
    <>
      <div className={adminstyles["admin-container"]}>
        <h1>
          Benefits <span> &gt; Administration</span>
        </h1>
        <div className={adminstyles["card-grid"]}>
          {cards.map((card, index) => (
            <div key={index} className={adminstyles["card-wrapper"]}>
              <div className={adminstyles["card"]}>
                <img
                  src={card.image}
                  alt={card.title}
                  className={adminstyles["card_image"]}
                />
                <div className={adminstyles["text-container"]}>
                  <h3 className={adminstyles["card_title"]}>{card.title}</h3>
                  <p className={adminstyles["card_subtitle"]}>
                    {card.subtitle}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="">
        <Section2 />
      </div>
      <div className="">
        <Section3 />
      </div>
    </>
  );
};

export default Adminstration;
