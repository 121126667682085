import React from "react";
import Section1Styles from "./section.module.scss";
import st1 from "assets/images/st1.png";
import st2 from "assets/images/st2.png";
import st3 from "assets/images/st3.png";

const cards = [
  {
    image: st1,
    title: "Congratulating Our Graduates",
    description:
      "Congratulating scholars on the completion of their undergraduate programs.",
  },
  {
    image: st2,
    title: "Digital Skills Help Women Break Barriers",
    description:
      "Congratulating scholars on the completion of their undergraduate programs.",
  },
  {
    image: st3,
    title: "Developing Skills for Success",
    description:
      "In its first four years, Skills For Success program has improved the employment prospects for nearly 5,000 young men and women region-wide.",
  },
];

const Testimonials: React.FC = () => {
  return (
    <div className={Section1Styles["testimonials"]}>
      <h2>Success story</h2>
      <div className={Section1Styles["tilted-rectangles"]}>
        <div></div>
        <div></div>
        <div></div>
      </div>

      <div className={Section1Styles["overlay-card-grid"]}>
        {cards.map((card, index) => (
          <div key={index} className={Section1Styles["card"]}>
            <img
              src={card.image}
              alt={card.title}
              className={Section1Styles["card-image"]}
            />
            <div className={Section1Styles["card-overlay"]}>
              <h3>{card.title}</h3>
              <p>{card.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Testimonials;
