import React from "react";
import Section1Styles from "./section.module.scss";
import boy from "assets/images/cookies.png";

const LearningSection: React.FC = () => {
  return (
    <div className={Section1Styles["container"]}>
      <section className={Section1Styles["learning-section2"]}>
        <div className={Section1Styles["learning-box"]}>
          <div className={Section1Styles["left"]}>
            <h2>What are cookies?
            </h2>
            <p>
            Cookies are text files or pixel tags which are placed on your smartphone, tablet, laptop, or computer when you visit a website. We use cookies when you visit our site, but you can choose to not allow some types of cookies. Blocking some cookies may impact your experience of the site and the services we are able to offer.  <br /><br />

The cookies we use do not access any personal data that you provide to UCAS as a charity, by this we mean the personal data you give to us so that we can process your application.<br /><br />

We may personalise ads that you see by tracking your online activity, such as pages visited and searches and when you favourite a page or a provider in the Hub, but only with your consent.<br /><br />

            </p>
            
          </div>
        </div>
        <div className={Section1Styles["image"]}>
          <div className={Section1Styles["animated-dots-wrapper"]}>
            <div className={Section1Styles["animated-dots"]}>
              <img
                src={boy}
                alt="Animated Dots Example"
                className={Section1Styles["image"]}
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default LearningSection;
