import React from "react";
import Section1Styles from "./section.module.scss";
import mb from "assets/images/mb.png";
import google from "assets/images/googleplay.png";
import apple from "assets/images/applestore.png";

const Section5: React.FC = () => {
  return (
    <div className={Section1Styles["phone"]}>
      <div className={Section1Styles["left"]}>
        <h4>A TEST PREP APP DESIGNED FOR</h4>
        <h2>On-the-Go Learning</h2>
        <p>
          We get it—people are busy. But with our mobile app, finding time to
          meet your goals while you're on the go is possible—micro-learning at
          its finest! The best part about it? It’s free with your subscription.
        </p>
        <div className={Section1Styles["imgstor"]}>
          <img src={google} className={Section1Styles["stor"]} alt="phone" />
          <img src={apple} className={Section1Styles["stor"]} alt="phone" />
        </div>
      </div>
      <div className={Section1Styles["right"]}>
        <div className={Section1Styles['right-img']}>
        <img src={mb} className={Section1Styles["imphon"]} alt="phone" /></div>
      </div>
    </div>
  );
};

export default Section5;
