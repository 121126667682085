import React from "react";
import SpinnerStyles from "./Spinner.module.scss";

const Spinner: React.FC = () => {
  return (
    <>
      <div className={SpinnerStyles.spinner}>
        <div className={`${SpinnerStyles.circle} ${SpinnerStyles.circle1}`}></div>
        <div className={`${SpinnerStyles.circle} ${SpinnerStyles.circle2}`}></div>
        <div className={`${SpinnerStyles.circle} ${SpinnerStyles.circle3}`}></div>
        <div className={`${SpinnerStyles.circle} ${SpinnerStyles.circle4}`}></div>
      </div>
    </>
  );
};

export default Spinner;
